import type { TranslationMap } from "./";

const nl:TranslationMap = {
	mvet: {
		myMvet: "<b>My</b> mVet",
		goToShop: "Naar de winkel",
		goToLibrary: "Go to Library",
		goToAccount: "Go to Account",
		yourOrders: "Your Orders"
	},
	home: "Startpagina",
	index: "Inhoudsopgave",
	announcements: "Announcements",
	delete: "Verwijder",
	save: "Red",
	saveCorrection: "Red",
	download: "Download",
	editTask: "Opdracht bewerken",
	title: "Titel",
	addNote: "Notitie maken",
	files: "Bestanden",
	perFile: "per bestand",
	supportedFiles: "Ondersteunde bestandsformaten",
	login: "Aanmelden",
	logout: "Afmelden",
	starlevel: "Op welk niveau wilt u het hoofdstuk uitwerken?",
	lastVisitedPages: "Laatst bezochte pagina’s:",
	author: "Auteur",
	upload: "Bestand uploaden",
	uploadError: "Helaas kon het geselecteerde bestand niet worden opgeslagen, misschien is het te groot.",
	uploadTooBig: "Helaas passen deze bestanden niet in het lokale geheugen. Probeer het opnieuw met kleinere of minder bestanden.",
	notesPublish: "Publiceren voor de klas",
	notesLogin: "Log in om notities te maken.",
	notesAdd: "Nieuwe marker maken",
	notesRemove: "Markering verwijderen",
	notesClose: "Menu markering sluiten",

	coursePadHeader: "Groepswerk",
	coursePadNew: "Nieuwe groep",
	coursePadError: "Gebruik een naam die tussen de 4 en 64 tekens lang is.",
	
	submitCorrection: "Indienen voor correctie",
	correctionSubmitted: "Correctie ingediend",
	correctionSubmittedModal: "De taak werd ter correctie voorgelegd.",
	editLocked: "Verdere verwerking is daarom momenteel niet mogelijk.",
	//optout
	allowExternalEmbeds: "Toestemming om externe inhoud te laden",
	darkMode: "Darkmode instelling",
	page_view_history: "Laatst bekeken gegevens",
	LANG: "Taalinstelling",
	local_only_ugm_notes: "Lokale notities",
	user_star_level: "Globaal moeilijkheidsniveau",
	star_data: "Opgeslagen differentiaties",
	local_only_ugm_correction: "lokaal opgeslagen invoer van je oefeningen",
	//dynamic Pages
	glossary: "Woordenlijst (Collectie)",
	//Contenttypes
	//embeds / privacy
	onlyAllowEmbedsForLoggedInUsers: "Om deze interactieve inhoud te gebruiken, logt u in.",
	externalEmbed: "Externe inhoud, klik hier om verder te gaan",
	externalEmbedModal:`<p>Op dit punt vindt u externe inhoud. Als u dergelijke inhoud inschakelt, kunnen persoonlijke gegevens worden verzonden naar platforms van derden. </p><p>Meer hierover in onze <a href="./datenschutz/" target="_blank">verklaring over gegevensbescherming</a>.</p>`,
	accept: "Accepteren",
	cancel: "Annuleren",
	privacyContainer: "Hier vindt u uw momenteel opgeslagen instellingen",
	//ugm login fields
	activationAccess: "AActiveer uw toegang voor",
	activationSubmit: "Laten we gaan!",
	activationSuccessHeader: "Uw identificatiecode",
	activationSuccessText: "Schrijf je wachtwoord op en onthoud het goed! Als je je wachtwoord vergeet, kan je docent het voor je resetten.",
	activationFailedHeader: "Fout tijdens gebruikersactivering",
	activationFailedText:"Helaas is er een fout opgetreden tijdens de activering van de gebruiker. Controleer de link en probeer het later nog eens.",
	activationError: "Aktivierung fehlgeschlagen, bitte prüfen Sie Ihre Eingabe und versuchen Sie es erneut.",

	registerText: "Heb je nog geen toegang voor je klas? Registreer je dan hier!",
	registerTextEnforced: "Heb je nog geen account? Registreer je dan hier!",
	registerTextGeo: "Activering mislukt, controleer je invoer en probeer het opnieuw.",

	userName: "Gebruikersnaam",
	password: "Wachtwoord",
	passwordInfo: "Het wachtwoord moet minimaal 8 tekens lang zijn.",
	passwordRepeat: "Herhaal wachtwoord",
	passwordEnter: "Wachtwoord invoeren",
	passwordReset: "Reset wachtwoord voor",
	passwordError: "Het wachtwoord is te kort",
	passwordDiffers:"Die Passwörter stimmt nicht überein",

	loginError: "Inloggen is mislukt, controleer je invoer en probeer het opnieuw.",
	loginInfoUsername: "Als Lehrperson melden Sie sich mit Ihrer E-Mail-Adresse an.<br /> Als Schülerin oder Schüler melden Sie sich mit Ihrer Kürzel im Format \"xxxx-farbetier\" an.",
	loginInfoUsername365: "<p>Ostbelgien: Gebruik de knop \"Doorgaan met Office 365\" hieronder.</p><p>Alle andere regio's van de Euregio Maas-Rijn: </p><ul><li><p>Log als leerkracht in met je werkmail. </p></li><li><p>Leerlingen gebruiken hun afkorting in het formaat \"xxxx-kleurdier\".</p></li></ul><p></p>",
	loginInfoPassword: `Als je als leerling je wachtwoord bent vergeten, neem dan contact op met de verantwoordelijke docent. Zij kunnen je wachtwoord resetten en je een tijdelijk wachtwoord geven.</br></br>Als u als docent uw wachtwoord bent vergeten,`,
	loginInfoPasswordLink: "Wachtwoord resetten",
	loginInfoPassword365: (link:string) => `<p>Voor Ostbelgien: Reset indien nodig je wachtwoord via MS Office 365.&nbsp;</p>
	<p>Alle andere regio's van de Euregio Maas-Rijn:&nbsp;</p>
	<ul>
		<li>Als je een leerkracht bent en je wachtwoord bent vergeten, Klik op ${link}.</li>
		<li>Als je een leerling bent en je wachtwoord bent vergeten, neem dan contact op met je docent. Zij kunnen je wachtwoord resetten en je een tijdelijk wachtwoord geven</li>
	</ul>`,
	login365Label: "Doorgaan met Office 365",
	login365Hint: "Opmerking: in Oost-België is geen registratie vereist, klik direct op \"Doorgaan met Office 365\".",
	loginVidisLabel: "Weiter mit",
	loginVidisHint: "Hinweis: mit VIDIS ist keine Registrierung nötig, klicken Sie direkt auf \"Weiter mit VIDIS\".",

	loginEduplacesLabel: "Weiter mit",
	loginEduplacesHint: "Hinweis: mit Eduplaces ist keine Registrierung nötig, klicken Sie direkt auf \"Weiter mit Eduplaces\".",

	invalidOIDCEmailTitle: "Ongeldig e-mail",
	invalidOIDCEmailBody: "Gebruik een officieel e-mailadres van uw school.",

	classList: "Klasse lijst",
	classManagement: "Klassenmanagement",
	correctSubmissions: "Correcte taken",
	sendMessage: "Bericht versturen",
	messages: "Nieuws",

	//privacy policy
	acceptAgb: (link:string) => `Ik heb het ${link} gelezen en geaccepteerd`,
	privacyPolicy: "privacybeleid",
};
export default nl;
